.widget-body.scatter {
    margin-bottom: 0px;
    margin-right: 23px;
    margin-left: 11px;
    margin-top: 50px;
}

.widget-body.table-with-scatter {
  	margin-bottom: 0px;
  	margin-right: 23px;
 	margin-left: 23px;
 	width: 95%;
 	margin-top: 50px;
}

.scatter-plot-legend.bluetooth {
    width: 74%;
    left: 154px;
}

.scatter-plot-legend {
    position: absolute;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(3, 1fr);
    width: 60%;
    height: 26px;
    left: 219px;
    top: -33px;
    
    .scatter-plot-legend-color {
        display: inline-block;
        text-align: center;
        line-height: 24px;
        user-select: none;
        cursor: pointer;
        height: 20px;
        width: 20px;
    }

    .scatter-plot-legend-label {
        display: inline-block;
        margin-left: 5px;
        line-height: 24px;
        vertical-align: top;
        font-family: 'Inter', sans-serif;
        cursor: pointer;

        span {
            font-size: 15px;
        }
    }
}

.switch-view-button {
    width: 90px;
    position: absolute;
    top: 30px;
    height: 30px;
    text-align: center;
    line-height: 29px;
    cursor: pointer;
    font-size: 12px;
    right: 5px;
}

.scatter-y-axis-label {
    position: absolute;
    left: -18px;
    top: 155px;
    transform: rotate(-90deg);
    padding-bottom: 20px;
    padding-left: 7px;
    color: #a9a9a9;
    font-weight: normal;
    font-size: 13px;
}

.scatter-x-axis-label {
    text-align: center;
    margin-top: 10px; 
    font-weight: 700;
    padding-bottom: 20px;
    padding-left: 7px;
    color: #a9a9a9;
    font-weight: normal;
    font-size: 13px;
}
