.accordion {
    width: 100%;
    margin-bottom: 25px;
}

.accordion .card-header {
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.12);
    background-color: #ffffff !important; /* need this? */
}

.AccordionButton {
    float: right !important;
    border: none !important;
    background: none;
    color: #2a7fc9; /*#00539D;*/
    padding: 0;
    cursor: pointer;
    border-color: #f7f7f7 !important;
    outline-color: #f7f7f7 !important;
    outline-width: 0 !important;
    margin-top: 15%;
}

.AccordionButton .custom-fa {
    font-size: 1.1em;
}

.AccordionButton span {
    /*float: left;*/
    font-size: 15px !important;
    font-weight: 600;

}

.AccordionButton .hidden {
    visibility: hidden;
}

.filters-checkbox-list {
    list-style: none;
    margin: 0;
    padding: 10px 0 10px 10px;
    /*height: 132px;*/
    overflow-y: auto;
    background-clip: border-box;
    border-radius: 2px;
    overflow-x: hidden;
    height: 145px;
}

.filters-checkbox-list:not(.screen-transition-items) li {
    /*line-height: 30px;*/
    line-height: 35px;
    max-height: 35px;
}

.filters-checkbox-list:not(.screen-transition-items) li div:first-child {
    /*float: left;*/
    /*top: 3px;*/
    width: 22px;
    height: 22px;
}

/*ul li input[type=checkbox] {
    transform: scale(1.5);
    margin-right: 10px;
}*/

.checkbox-title {
    position: relative;
    left: 29px;
    top: -28px;
    font-size: 15px;
}

.DateRangePicker {
    margin-top: 5px;
}

.accordion .card {
    margin-top: 0 !important;
}

.accordion .card-title {
    font-size: 1rem !important;
}

.margin-top-20 {
    margin-top: 20px !important;
}

.margin-left-10 {
    margin-left: 10px !important;
}

.filter-date-desc {
    font-size: 16px;
    color: #616161;
    font-family: 'Inter SemiBold';
}

.info {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.55);
    font-weight: 600;
}

.applied-filter-container {
    /*margin-top: 10px;*/
    max-height: 100px;
    overflow-y: auto !important;
}

.filter-chip {
    font-size: 14px !important;
    margin-top: 10px;
    margin-right: 10px;
}

.filter-chip.disabled {
    color: rgb(148, 148, 148);
    background-color: rgb(224, 224, 224);
}

.filter-chip.disabled:hover {
    color: rgb(148, 148, 148) !important;
    background-color: rgb(224, 224, 224) !important;
}

.accordion-collapse {
    background-color: #ffffff;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,.12);
    /*border: 1px solid #c0c0c0 !important;*/
    position: absolute;
    width:100%;
    z-index:1;
    top: 100%;
    /* transform: translateY(25%); */
    /* margin-top: calc(10% - 10px); */
    /* margin-top: 124px; */
    /* top: 250px; */
}

.accordion-collapse .card {
    min-width: auto;
    border: 1px solid #c4c4c4;
    border-radius: 0.15rem;
}

.accordion-collapse .card-body {
    padding-bottom: 24px;
}

.accordion .card-title {
    color: #00539D;
    font-weight: 700;
    font-size: 15px !important;
}

.button-primary, .button-secondary {
    border-radius: 6px !important;
}

.button-secondary {
    padding-left: 18px !important;
    padding-right: 18px !important;
}

.button-primary:focus, .button-secondary:focus {
    box-shadow: none !important;
}

.tail-select > div > div > div {
    font-family: 'Inter regular', sans-serif;
    font-size: 15px;
}

.select__indicator-separator, .select-long__indicator-separator {
    display: none !important;
}

.select__single-value, .select__placeholder, .select-long__single-value, .select.long__placeholder {
    font-size: 15px !important;
}

.select__control {
    border-radius: 2px !important;
    max-width: 310px;
    min-height: 40px !important;
}

.select-long__control {
    border-radius: 2px !important;
    max-width: 430px;
}

.select__control:hover, .select-long__control:hover {
    border-color: hsl(0,0%,50%) !important;
}

.select__control--is-focused, .select__control--menu-is-open, .select-long__control--is-focused, .select-long__control--menu-is-open {
    border-color: hsl(0,0%,50%) !important;
    box-shadow: rgb(148, 148, 148) 0 0 0 1px inset !important;
}


/*.select__dropdown-indicator svg path {
    d: path('M10 12.5l-4.375-5h8.75z') !important;
}

.select-long__dropdown-indicator svg path {
    d: path('M10 12.5l-4.375-5h8.75z') !important;
}


.select-long__indicator--menu-is-open .select-long__dropdown-indicator svg path {
    d: path('M10 12.5l-4.375-5h8.75z') !important;
}*/


/*.select__dropdown-indicator {*/
/*    color: hsl(0,0%,60%) !important;*/
/*}*/

/*.select__dropdown-indicator:hover {*/
/*    color: hsl(0,0%,30%) !important;*/
/*}*/

/*.select__indicator .select__dropdown-indicator {*/
/*    color: hsl(0,0%,30%) !important;*/
/*}*/


.select__control .select__dropdown-indicator {
    color: hsl(0,0%,60%) !important;
}

.select__control--is-focussed  .select__dropdown-indicator {
    color: hsl(0,0%,30%) !important;
}

.select-long__control .select-long__dropdown-indicator {
    color: hsl(0,0%,60%) !important;
}

.select-long__control--is-focussed  .select-long__dropdown-indicator {
    color: hsl(0,0%,30%) !important;
}

.DateRangePicker .DateRangePicker_picker {
    z-index: 5;
}