.widget-frame {
	position:relative;
	background-color: white;
	min-height: 570px;
	box-shadow: 0 2px 8px 0 rgba(0,0,0,0.12);
	grid-column: span 6;
}

.widget-frame.screen-transition {
	height: 640px;
}

.widget-frame.carousel {
	height: 509px;
	min-height: 509px;
}

.widget-frame.bar {
	height: 540px; /*509px;*/
	min-height: 540px;
	grid-column: span 3;
}

.widget-frame.donut {
	height: 540px; /*509px;*/
	min-height: 540px;
	grid-column: span 3;
}

.widget-frame.donut.no-workbench {
	/*height: 470px;*/
}

.widget-frame.trends.no-workbench {
	height: 500px;
}

.widget-frame.trends.no-workbench.bluetooth {
	height: 550px;
	min-height: 500px;
}

.widget-frame.trends.bluetooth {
	min-height: 610px
}

.widget-frame.trends.bluetooth .tabs {
	margin-top: 4px;
}

.widget-frame.donut-small {
	height: 350px;
	grid-column: span 2;
}

.widget-frame.line{
	height: 598px;
}

.widget-frame.plane {
	height: 488px;
	min-height: 488px;
}

.ped-icon {
	margin-top: -14px;
	margin-left: 15px;
}

.ped-icon.text {
	display: inline;
	margin-left: 16px;
	font-weight: bold;
	color: #DF9B19;
	vertical-align: bottom;
}

.ped-icon.text.label {
	font-size: 21px;
	font-family: 'Inter Bold';
	margin-left: 0px;
}

.ped-icon.text.value {
	font-size: 21px;
	font-family: 'Inter Regular';
	font-weight: normal;
	margin-left: 10px;
}

.ped-icon.text.change-value {
	font-weight: normal;
	font-size: 16px;
	margin-left: 10px;
	font-family: 'Inter Regular';
	line-height: 28px;
}

.widget-title {
	display: block;
	margin-left: 24px;
	margin-top: 24px;
	height: 36px;
	font-size: 21px;
	font-weight: 600;
	color: #00539D;
	font-weight: bold;
	line-height: 25px;
}

.widget-title.donut-small {
	font-size: 19px;
}

.widget-info {
	position: absolute;
	right: 48px;
	top: 26px;
	font-size: 16px;
	font-weight: bold;
	width: 28px;
	height: 28px;
	text-align: center;
	line-height: 28px;
	cursor: pointer;
	color: #2A7FC9;
	user-select: none;
	display: none;  /*TODO: enable after demo */
}

.widget-info-popup {
	position: absolute;
	right: 76px;
	top: 52px;
	width: 430px;
	height: 280px;
	transition: all .25s ease-in-out;
	z-index: 10;
	border-radius: 10px;
	backdrop-filter: blur(16px);
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
	background: linear-gradient(180deg, rgba(252,254,238,0.5) 0%, rgba(230,255,244,0.5) 100%);
}

.widget-info-popup.closed {
	height: 0;
	width: 0;
}

.widget-overflow {
	position: absolute;
	right: 24px;
	top: 24px;
	font-size: 22px;
	/*font-weight: bold;*/
	width: 30px;
	height: 30px;
	text-align: center;
	line-height: 29px;
	cursor: pointer;
	user-select: none;
	/*display: none;*/  /*TODO: enable after demo */
	/*z-index: 2;*/
	border-radius: 2px;
	/*background-color: #e5e5e5;*/
}

.widget-overflow:hover {
	background-color: #e5e5e5;
}

.widget-overflow-popup {
	position: absolute;
	right: 12px;
	top: 18px;
	width: 203px;
	height: auto; /* 245px; TODO: change it to 245px after adding more items*/
	transition: all .25s ease-in-out;
	/*z-index: 1;*/
	border-radius: 10px;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
	background-color: #ffffff;
	transform-origin: top right;
	overflow:hidden;
}

.widget-overflow-popup.closed {
	color: transparent;
	transform: scale(0);
}

.widget-overflow-option {
	height: 40px;
	width: 203px;
	color: #909090;
	cursor: pointer;
}

.widget-overflow-option:hover{
	background-color: #F9F9F9;
}

.widget-overflow-option.disable {
	background-color: #f9f9f9;
	cursor: not-allowed;
}

.widget-overflow-label.disable {
	color: #949494;
	cursor: not-allowed;
}

.widget-overflow-img {
	display: inline-block;
	padding-left: 16px;
	padding-right: 16px;
	height: 18px;
	/*width: 18px;*/
	vertical-align: middle;
}

.widget-overflow-label {
	display: inline-block;
	padding-top: 0px;
	height: 40px;
	line-height: 40px;
}

.widget-overflow-divider {
	height: 1px;
	width: 203px;
	background-color: #EDEDED;
}

.widget-frame-dropdown {
	/*padding-right: 16px;*/
	margin-left: 38px;
	/*height: 40px;*/
	width: 51%;
	/*width: 248px;*/
}

.widget-frame-dropdown-1 {
	margin-left: 38px;
	/*height: 40px;*/
	width: 30%;
}

.widget-frame-dropdown-2 {
	margin-left: 20px;
	/*height: 40px;*/
	width: 47%;
}

.widget-frame-dropdown-3 {
	/*padding-right: 16px;*/
	margin-left: 38px;
	/*height: 40px;*/
	width: 51%;
	/*width: 248px;*/
}

.widget-frame-dropdown-3 .select__control {
	max-width: 360px !important;
}

.widget-body {
	position:relative;
	margin-top: 16px;
}
 
.widget-body.donut{
	margin-top: 25px;
}

.widget-body.bar{
	margin-top: 50px;
}

.widget-body.plane {
	margin-top: 47px;
	margin-left: 10px;
}

.widget-body.trends.bluetooth {
	margin-top: 8px;
}

.widget-body.trends.bluetooth .chart-y-axis {
	padding-bottom: 10px;
}

.workbench-button {
	position: absolute;
	right: 32px;
	bottom: 20px;
	font-size: 16px;
	font-weight: bold;
	color: #3D9FDA;
	text-decoration: none !important;
	cursor: pointer;
}
.alertInfo-icon{
	position:relative;
	padding: 0px 5px;
	cursor: pointer;
	margin-top: -3px;
}
.alertInfo-icon:hover + .alertInfo-popup-container{
	display:block;
}
.alertInfo-popup-container{
	width: 425px;
	border-radius: 3px;
	background-color: #FFFFFF;
	box-shadow: 0 1px 6px 0 rgba(0,0,0,0.5);
	position: absolute;
	z-index: 1;
	padding:5px;
	margin-top:10px;
	transition:0.5s;
	display:none;
}
.alertInfo-popup{
	color: #000000;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 22px;
	font-weight: normal;
	padding:15px;

}
.tabs {
	overflow: hidden;
	margin-left: 22px;
	margin-top: 10px;
	padding-bottom: 4px;
}

.tabs div:last-child {
	margin-right: 0;
}

.tabs > .tab.bluetooth:nth-child(2) {
	width: 185px !important;
}

.tabs > .tab.bluetooth:nth-child(3) {
	width: 210px !important;
}

.tab {
	width: 175px;
	/*height: 126px;*/
	height: 104px;
	float: left;
	font-size: 14px;
	margin-right: 26px;
	/*color: #4e5d78;*/
	cursor: pointer;
	border-bottom: 5px solid #ced2d9;
}

.tab.bluetooth {
	margin-right: 16px !important;
}

.tab.free {
	height: 70px;
}

.tab:hover {
	border-bottom: 5px solid #c0c4cb;
}

.tab.active, .tab.free.active {
	font-weight: 500;
	color: #000000;
	border-bottom: 5px solid #2A7FC9;
}

.tab.active.ereader,.tab.ereader {
	width: 146px;
	margin-right: 22px;
}

.tab-title {
	font-size: 15px;
	height: 24px;
	color: #7a7a7a;
}

.tab.active .tab-title {
	color: #000000;
}

.tab-change {
	/*font-size: 40px;*/
	/*height: 60px;*/
	font-size: 15px;
	font-weight: bold;
	/*height: 48px;*/
	/*padding-bottom: 6px;*/
}

.tab-data {
	font-size: 19px;
	height: 40px;
	padding-top: 8px;
}

.tab.free .tab-data {
	padding-top: 5px;
}

.tab-arrow {
	font-size: 22px;
	vertical-align: top;
	padding-right: 4px;
}

.page-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(380px, 1fr));
	grid-gap: 24px;

	max-width: 1032px;
	padding-bottom: 44px;
	transition: all .6s ease-in-out;
}

.widget-image {
	margin-left: 24px;
	height: 24px;
}

.widget-title.no-image {
	margin-left:0px;
}

.widget-footer {
	position:absolute;
	width: 100%;
	bottom: 0;
	height: 41px;
	border-top: 1px solid #CED2D9;
}

.widget-dropdown {
	background-color: transparent;
	color: #00539D;
	font-size: 13px;
	border-color: transparent;
}

.widget-dropdown:focus {
	outline: none;
}

.widget-dropdown-carousel {
	margin-bottom: 25px;
	margin-top: 4px;
}

.widget-dropdown-bar {
	margin-top: 4px;
}

.widget-dropdown-carousel .widget-frame-dropdown {
	margin-left: 38px;
	width: 25%;
}

.table-sort-button-selected {
	border: 0;
	font-weight: 700;
	background: none;
	color: #2A7FC9;
	padding-left: 0;
}

.table-sort-button-not-selected {
	border: 0;
	font-weight: normal;
	background: none;
	color: #000000;
	padding-left: 0;
}

.table-sort-button-selected img {
	margin-left: 5px;
}