@import "variables.scss";

/* TODO make -> .main-content .top-header ---> z-index: 3;*/
.admin-start {
    min-height: 450px;
}

.admin-outer-container {
    margin-top: 14px;

    .button-primary,
    .button-secondary {
        border-radius: 2px !important;
    }

    .button-primary {
        font-size: 16px;
        padding-left: 24px;
        padding-right: 20px;
        line-height: 24px;
        font-weight: normal;
    }

    .button-secondary {
        height: 40px;
        width: 115px;
        font-size: 16px;
        padding-left: 24px;
        padding-right: 24px;
        line-height: 24px;
        font-weight: 500;
    }

    .tail-controls-container {
        width: 1032px;
        display: flex;
        flex-wrap: wrap;

        .button-secondary:focus {
            color: #ffffff !important;
            box-shadow: inset 0 0 0 2px #184a75, inset 0 0 0 4px #ffffff !important;
        }
    }

    .applied-filter-container {
        width: 1032px;
        margin-top: 4px;
        max-height: 100px;
        overflow-y: auto !important;
    }

    .admin-container {
        box-sizing: border-box;
        width: 1032px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        margin-top: 14px;
        overflow: auto;

        table {
            width: 100%;
            border-collapse: separate;
            border-spacing: 0;

            td,
            th {
                white-space: nowrap;
            }

            .sticky-left {
                position: sticky;
                left: 0;
                /* Add space before first td/th when overlow = initial */
                z-index: 2;
                background: inherit;
            }

            .sticky-left-2 {
                position: sticky;
                left: 60px;
                /* Add space before first td/th when overlow = initial */
                z-index: 2;
                background: inherit;
            }

            .sticky-right {
                position: sticky;
                right: 0;
                z-index: 2;
                background: inherit;
            }

            .sticky-right.top {
                z-index: 3;
            }

            thead {
                height: 57px;
                background-color: #fcfcfc;

                th {
                    height: 24px;
                    color: #4b4b4b;
                    font-family: 'Inter', sans-serif;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0;
                    line-height: 24px;
                    padding-left: 7px;
                    padding-right: 7px;
                    text-align: left;
                    position: relative;
                    background-color: #fcfcfc;
                }

                th:first-child {
                    padding-left: 24px;
                }

                th:last-child {
                    padding-right: 24px;
                }

                th.sticky-left,
                th.sticky-left-2,
                th.sticky-right {
                    background-color: #fcfcfc;
                }
            }

            tbody {
                tr {
                    height: 56px;

                    td {
                        height: 24px;
                        color: #000000;
                        font-family: 'Inter', sans-serif;
                        font-size: 16px;
                        letter-spacing: 0;
                        line-height: 24px;
                        text-align: left;
                        padding-left: 7px;
                        padding-right: 7px;
                        position: relative;
                        border-top: 0.5px solid rgba(0, 0, 0, 0.1);
                    }

                    td:first-child {
                        padding-left: 24px;
                    }

                    td:last-child {
                        padding-right: 24px;
                    }
                }

                tr:nth-child(even) {
                    background: #fcfcfc;
                }

                tr:nth-child(odd) {
                    background: #ffffff;
                }

                tr:nth-child(even) td {
                    background: #fcfcfc;
                }

                tr:nth-child(odd) td {
                    background: #ffffff;
                }
            }
        }
    }

    .card {
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, 0.1) !important;
        border-radius: 2px;
        margin: 0;
    }

    .card-header {
        border-bottom: none !important;
        padding: 16px 16px !important;
        box-shadow: none !important;
    }

    .card-body {
        padding: 0px 24px 24px 36px !important;

        .row:nth-child(1),
        .row:nth-child(2) {
            margin-right: 0 !important;
            margin-left: 0 !important;
        }
    }

    .card-title {
        width: 97%;
        color: #000000 !important;
        font-size: 16px !important;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        display: inline-flex;
        margin-bottom: 0;

        span {
            font-weight: normal !important;
            color: #7a7a7a !important;
            padding-left: 5px !important;
        }
    }

    .accordion {
        width: 100%;
        margin-bottom: 15px !important;
    }

    .accordion-button {
        text-align: left;
        border: none;
        background: none;
        padding: 0;
    }

    .fa-container {
        width: 20px !important;
    }

    .admin-container.license {
        overflow: initial;
        margin-top: 0;

        .airline-name {
            min-width: 140px;
            text-align: left !important;
            padding-right: 20px !important;
        }

        .tier {
            min-width: 80px;
            max-width: 80px;
            text-align: left !important;
            padding-right: 20px !important;
        }

        .stage {
            min-width: 70px;
            max-width: 70px;
            text-align: center !important;
            padding-right: 20px !important;
        }

        .start-date {
            min-width: 100px;
            max-width: 100px;
            text-align: left !important;
            padding-right: 20px !important;
        }

        .end-date {
            min-width: 140px;
            text-align: left !important;
            padding-right: 20px !important;
        }

        .last-updated {
            min-width: 140px;
            text-align: left !important;
            padding-right: 20px !important;
        }
    }

    .admin-container.tail {
        margin-top: 0 !important;

        .check-box {
            min-width: 50px;
            text-align: center !important;
            padding-right: 20px !important;
        }

        .tail {
            min-width: 160px;
            text-align: left !important;
            padding-right: 20px !important;
        }

        .tier {
            min-width: 160px;
            text-align: left !important;
            padding-right: 20px !important;
        }

        .aircraft {
            min-width: 160px;
            text-align: left !important;
            padding-right: 20px !important;
        }

        .system {
            min-width: 120px;
            text-align: center !important;
            padding-right: 20px !important;
        }

        .app {
            min-width: 120px;
            text-align: center !important;
            padding-right: 20px !important;
        }

        .last-updated {
            min-width: 140px;
            text-align: left !important;
            padding-right: 20px !important;
        }
    }

    .admin-container::-webkit-scrollbar {
        overflow: hidden;
        background-color: #ededed;
        height: 10px;
    }

    .admin-container::-webkit-scrollbar-thumb {
        background: #adadad;
        border-radius: 25px;
        height: 10px;
    }

    .admin-container.loading {
        height: 300px;
        padding-top: 9%;
        text-align: center;
        background-color: #ffffff;
    }

    .admin-container.data-not-available {
        height: 300px;
        padding-top: 70px;
        text-align: center;

        .no-airline-title {
            font-family: 'Inter Bold', sans-serif;
            color: #616161;
            font-size: 18px;
            margin-bottom: 16px;
            margin-top: 40px;
        }

        .no-airline-body {
            font-family: 'Inter Regular', sans-serif;
            color: #616161;
            font-size: 16px;
            margin-bottom: 32px;
        }

        .no-tail-title {
            font-family: 'Inter Bold', sans-serif;
            color: #616161;
            font-size: 18px;
            margin-bottom: 16px;
        }

        .no-tail-body {
            font-family: 'Inter Regular', sans-serif;
            color: #616161;
            font-size: 16px;
            margin-bottom: 32px;
        }
    }

    .license-container.data-not-available {
        height: 275px;
        padding-top: 42px;
        text-align: center;
        width: 1032px;
        border: 1px solid rgba(0, 0, 0, .1);
        border-radius: 2px;

        .no-license-title {
            font-family: 'Inter Bold', sans-serif;
            color: #616161;
            font-size: 18px;
            margin-bottom: 16px;
        }

        .no-license-body {
            font-family: 'Inter Regular', sans-serif;
            color: #616161;
            font-size: 16px;
            margin-bottom: 32px;
        }
    }

    /* workaround to avoid space before first td/th when overflow = initial */
    .admin-container.license table .sticky-left {
        left: -13px;
    }

    .total-tails {
        padding-top: 20px;
        opacity: 0.8;
    }

    .pad-license {
        padding: 1px 9px;
    }

    .pad-top-license {
        padding-top: 19px;
    }

    .pagination-container {
        width: 100% !important;
        font-family: 'Inter regular', sans-serif;

        * {
            font-family: inherit;
        }
    }

    .widget-overflow {
        top: 14px;
        right: 16px;
    }

    .widget-overflow-popup {
        top: 8px;
        width: 102px;
        border-radius: 6px;
        box-shadow: 0 2px 8px 1px rgba(0, 0, 0, 0.2);
    }

    .Inactive {
        .widget-overflow-popup {
            width: 140px;
        }
    }

    .widget-overflow-label {
        padding-left: 16px;
    }

    .widget-overflow-option {
        width: 102px;
        color: #616161;
    }

    .icon-check-mark {
        font-size: 12px;
        color: #2A7FC9;
    }

    .admin-tail-select>div>div>div {
        font-family: 'Inter regular', sans-serif;
        font-size: 15px;
    }

    .admin-tail-select>div>div:nth-child(2) {
        height: 200px;
    }

    .admin-airline-select>div>div>div {
        font-family: 'Inter regular', sans-serif;
        font-size: 15px;
    }

    .admin-airline-select>div>div:nth-child(2) {
        height: 200px;
    }

    .filter-dropdown {
        position: absolute;
        height: 451px;
        width: 1032px;
        z-index: 3;
        top: 108px;
        border-radius: 2px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 8px -1px rgba(0, 0, 0, 0.55);
        transition: all .25s ease-in-out;
        padding-left: 0;
        overflow: hidden;

        .button-primary {
            height: 40px !important;
            width: 135px !important;
        }

        .button-secondary {
            height: 40px !important;
            width: 112px !important;
            border-radius: 2px !important;
            margin-left: 10px !important;
        }

        .card-body {
            padding: 30px 9px !important;
        }

        .card-title {
            color: #3E8ACD !important;
            height: 30px;
            width: 228px;
            font-family: 'Helvetica', sans-serif;
            font-size: 16px !important;
            font-weight: bold !important;
            letter-spacing: 0;
            line-height: 24px;
        }

        .card {
            min-width: auto;
            border: 1px solid #c4c4c4;
            border-radius: 0.15rem;
            margin-top: 0 !important;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 20px 0 20px 16px;
            overflow-y: auto;
            background-clip: initial;
            border-radius: 2px;
            overflow-x: hidden;
            height: 158px;
        }

        .ul-tiers {
            height: auto !important;
            max-height: 158px !important;
        }

        .ul-products {
            height: 230px !important;
        }

        .ul-stages {
            height: auto !important;
            max-height: 158px !important;
        }

        ul li {
            line-height: 35px;
            max-height: 22px;
            margin-bottom: 10px;
        }

        ul li:last-child {
            margin-bottom: 0;
        }
    }

    .filter-dropdown.closed {
        color: transparent;
        height: 0;
    }
}

.license-container.loading {
    height: 300px;
    padding-top: 9%;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.admin-airline-select>div>div>div {
    font-family: 'Inter regular', sans-serif;
    font-size: 15px;
}

.checkbox-title {
    line-height: 38px !important;
}

.tails-action-bar {
    position: fixed;
    display: flex;
    padding: 16px 24px;
    height: 64px;
    min-width: 471px;
    border-radius: 2px;
    background-color: #FFFFFF;
    bottom: 0;
    left: 640px;
    z-index: 2;
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);

    .selected-tails {
        min-width: 120px;
        line-height: 32px;
        margin-right: 16px;
    }

    .select-all-tails {
        height: 32px;
        width: 110px;
        margin-right: 16px;
        font-size: 14px;
        border-radius: 2px;
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    .vertical-line {
        box-sizing: border-box;
        height: 33px;
        border-left: 1.5px solid #e6e6e6;
        margin-right: 16px;
        margin-top: 0 !important;
    }

    .delete-tails {
        height: 32px;
        width: 76px;
        margin-right: 8px;
        font-size: 14px;
        padding-left: 16px !important;
        padding-right: 16px !important;
    }

    .edit-tails {
        height: 32px;
        width: 77px;
        font-size: 14px;
        padding-left: 16px !important;
        padding-right: 16px !important;
    }
}
