@import "sidebar.scss";
@import "charts.scss";
@import "fonts.scss";
@import "workbench.scss";
@import "cardLayout.scss";
@import "media-innovation.scss";
@import "admin.scss";
@import 'entertainment.scss';
@import 'layout.scss';
@import 'table-widget.scss';
@import 'scatter-plot.scss';

$font-regular: normal;

.custom-control.custom-switch {
	width: 0;
	height: 0;
	top: 100px;
	left: -20px;
	float: right;
	z-index: 1;
	padding-left: 0;
	min-height: 0;
}

.valign-mid {
	vertical-align: middle;
}

.page-header {
	color: #072457;
	font-size: 36px;
	font-family: 'Inter Bold', sans-serif;
	font-style: normal;
	letter-spacing: 0.45px;
	font-weight: bold;
	position: relative;
	top: -90px;
	left: 0px;
}

.modal-header,
.modal-body,
.modal-footer {
	border: 0;
}

.modal-body {
	.linechart-pop-align {
		padding-top: 135px;
		.min-top.card-body {
			padding-left: 0;
		}
	}
}

.modal-backdrop {
	/*background-color: white;*/ /* commented our per UX mock up */
}

.modal-backdrop.show {
	/*opacity: 0.8;*/ /* commented our per UX mock up */
}

.modal-header h5 {
	font-family: "Inter Bold", sans-serif;
	font-size: 24px;
	font-weight: 700;
	line-height: 32px;
}

.modal-body {
	font-family: "Inter Regular", sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	padding: 30px 54px 54px;
}

.modal-content .primary.btn-secondary {
	background-color: #2a7fc9;
	border: 1px solid #2a7fc9;
}

.main-content {
	background-color: #f9f9f9;
	flex-grow: 1;
	overflow-x: hidden;
	min-height: 100vh; // TODO: not needed after demo
	padding: 0 48px 120px 48px;
	//padding: 0 48px 48px 48px; // width: 690px;
	.col {
		padding-left: 1;
		padding-right: 0;
	}
	.card {
		/*box-shadow: 0 1px 2px #cfc9c9;*/
		min-width: 1032px;
		max-width: 1032px;
		border: none;
		margin-top:24px;
		/*height: 550px;*/
	}
	.card.bar-chart {
		min-width: 504px;
		max-width: 504px;
	}
	.small-bar-chart {
		height: 316px;
		min-width: 504px;
		max-width: 504px;
	}
	.bar-chart-gap {
		margin-top:24px;
		margin-right: 24px;
	}
	.card.small-donut-chart {
		min-width: 328px;
		max-width: 328px;
	}
	.align-component {
		width: 1032px;
		// margin-bottom: 26px;
	}
	.components-wrapper {
		height: 100%;
		margin-right: 2px;
	}
	.header {
		background-color: #f9f9f9;
		position: relative;
		display: $flex;
		max-width: 1032px; //z-index: 2;
		// padding-right: 100px;
		padding-left: 0px;
		padding-top: 55px; //padding: 20px 25px;
		padding-bottom: 20px;
		align-items: $center;
		/*height: 107px;*/
	}

	.date-row {
		position: relative;
		display: $flex;
		max-width: 1032px;
		padding-left: 0;
		/*padding-top: 2px;*/
		font-size: 22px;
		align-items: $center;
	}
	.page-date {
		float: left;
		padding-left: 0;
		font-weight: normal;
		font-size: 21px;
		font-family: "Inter regular",sans-serif ;
	}

	.selector-row {
		justify-content: $flex-end;
		align-items: $flex-end;
		flex-shrink: 0;
		/*margin-top: -50px;*/
	}
	.selector-label {
		display: $flex;
		align-self: $center;
		color: $black;
		font-size: $font14;
		letter-spacing: 0.23px;
		padding-right: 48px;
		//margin-top:50px;
	}

	.page-title {
		color: $page-title;
		font-size: 36px;
		font-family: 'Inter Bold', sans-serif;
		font-style: normal;
		letter-spacing: 0.45px;
		/*width: 30vw;*/
		float: left;
		padding: 0 15px 0 0 !important;
		margin-top: 10px;
	}

	.top-header {
		background-color: #f9f9f9;
		position: fixed;
		z-index: 5; // z-index: 2;
		top: 0;
		left: 344px;
		scroll-behavior: smooth;
		width: 1066px;
		max-width: 1066px;
		display: -webkit-flex;
		display: flex;
		padding-left: 0;
		// padding-top: 12px;
		padding-bottom: 12px;
		/*margin-top: 15px;*/
		// height: 65px;
		border-bottom: 1px solid rgba(183, 202, 221, 0.5);
		/*padding-top:40px;*/
		padding-top:15px;
		/*height:97px;*/
		height:72px;
	}

	.top-header-img {
		height: 22px;
		width: 20px;
		margin-right: 5px;
	}

	.quick-sight {
		letter-spacing: 0;
		/*width: 30vw;*/
		//max-width: 1032px;
		float: left;
		padding-left: 0;
		//padding-right: 0;
		margin-top: 0;
	}

	.component-title {
		font-size: 24px;
		font-weight: 700;
		letter-spacing: 0.45px;
	}

	.component-subtitle {
		font-size: 20px;
		font-weight: 700;
		letter-spacing: 0.45px;
	}

	.month-selector {
		.select__control {
			width: 142px;
			height: 40px;
			border-radius: 5px;
			border: 1px solid#2a7fc9;
			background-color: #2a7fc9;
			.select__value-container {
				justify-content: center;
				.select__placeholder {
					color: white;
				}
			}
		}
		.select__indicator-separator {
			display: none;
		}
		.select__single-value {
			color: $white;
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0.26px;
		}
		.select__dropdown-indicator {
			svg {
				fill: $white
			}
		}
	}

	.marginTop25 {
		margin-top: 25px;
	}

	.marginBottom25 {
		margin-bottom: 25px;
	}

	.no-pad-left-right {
		padding-left: 0;
		padding-right: 0;
	}

	.sweet-loading {
		margin-top: 10%;
	}

	.custom-loading {
		margin-top: 25%;
		text-align: center;
	}

	.custom-loading.carousel {
		margin-top: 13%;
		text-align: center;
	}

	.custom-loading.carousel.live-tv, .custom-loading.carousel.games, .custom-loading.carousel.movies {
		margin-top: 10%;
		text-align: center;
	}
	
	.custom-loading.table {
		margin-top: 15%;
		text-align: center;
	}
	
	.custom-loading.table-with-scatter {
		margin-top: 15%;
		text-align: center;
	}

	.custom-loading.trends {
		margin-top: 16%;
	}

	.custom-loading.screen-transition {
		margin-top: 20%;
	}

	.custom-loading.plane {
		margin-top: 12%;
	}
}

.next-insights-sup {
	position: absolute;
	top: 30px;
	font-size: 30px;
	// left: 225px;
	left: 260px;
	color: #082349;
}

.card.mask {
	background-color: lightgray;
}


.card.mask:before {
	content: 'Draft view';
	color: orange;
	font-size: 20px;
	text-align: $center;
	margin-top: 10px;
}

.container-fluid {
	padding: 0;
}

.container-fluid.main-container {
	.card-body {
		h4 {
			font-size: 1rem;
			font-weight: $bold;
		}
	}
	.row {
		flex-wrap: nowrap;
		margin-left: 0;
		margin-right: 0;
	}
	.mt-3 .row {
		min-width: 1032px;
		max-width: 1032px;
	}
	.row.align-component {
		.col:first-of-type {
			padding-right: 8px;
		}
		.col:nth-of-type(2) {
			padding-left: 8px;
		}
	}
}

div.link-arrow {
	margin: 0 0 0 5px;
	color: #2A7FC9;
	transform: rotate(180deg);
}

.card-footer {
	background-color: transparent;
	padding: 1px 0 1px 0;
	height: 40px;
	position: absolute;
	bottom: 0;
	width: 100%;
	.nav-link {
		font-family: 'Inter regular', sans-serif;
		font-size: 14px;
		margin-right: 15px;
		a {
			display: flex;
			color: #2a7fc9;
		}
	}
	.abs-right {
		position: absolute;
		right: 18px;
		top: 11px;
		padding: 0;
		display: flex;
	}
	.dropdown {
		.btn {
			color: #2a7fc9;
			font-family: 'Inter regular', sans-serif;
			font-size: $font14;
			.select__indicator-separator {
				display: none;
			}
		}
		.dropdown-item {
			color: #2a7fc9;
			font-family: 'Inter regular', sans-serif;
			font-size: $font14;
		}
	}
}

.fixed {
	position: fixed;
}


.footer {
	background-color: transparent;
}

.help {
	.getting-started {
		height: 24px;
		/*width: 135px;*/
		color: #000000;
		font-family: Inter, sans-serif;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 24px;
	}

	.outer-container {
		margin-top: 20px;
		width: 640px;

		.accordion {
			margin-bottom: 0;
		}

		.card {
			box-sizing: border-box;
			border: 1px solid #E0E0E0;
			border-radius: 2px;
			min-width: 640px;
			max-width: 640px;
			margin: 0;
		}

		.card-header {
			border-bottom: none;
			padding: 20px 20px;
			box-shadow: none;
		}

		.card-body {
			padding: 0 20px 24px;
		}

		.card-title {
			width: 97%;
			color: #000000;
			font-size: 16px !important;
			font-family: 'Inter', sans-serif;
			font-weight: 600;
			line-height: 24px;
			float: left;
			margin-bottom: 0;
		}

		.accordion-button {
			// float: right;
			text-align:left;
			border: none;
			background: none;
			padding: 0;
		}

		.border-bottom-none {
			border-bottom: none;
		}

		.video-container {
			margin: 15px 0 0 0 !important;
		}

		.fa-custom {
			font-size: 1.1em;
		}

	}
}



.release-notes .modal-content{
	max-width: 1066px;
	min-width: 1066px;
	min-height: 660px;
	top: -340px;
	left: -95px;
	border: none;
	box-shadow: 0 1px 8px #979797;
	border-radius: 2px;
	background-color: #ffffff;
	.modal-header {
		padding: 0;
		height: 0;
		h5.modal-title {
			color: white;
		}
	}
	.modal-body {
		padding: 30px 54px !important;
		.main-title > h1 {
			font-size: 36px;
			font-family: 'Inter Bold', sans-serif;
		}

		.main-title > div {
			font-size: 20px;
			font-family: 'Inter Bold', sans-serif;
			color: #082349;
		}

		.main-title > div > sup {
			font-size: 30px;
			font-family: 'Inter', sans-serif;
			top: 0;
			left: -8px;
		}

		.content {
			margin: 40px 0 0;
			padding-right: 32px;
			height: 480px;
			overflow-y: scroll;

			.section {
				padding-bottom: 6px;
				padding-top: 16px;
			}

			.section-2 {
				padding-bottom: 22px;
			}

			.version {
				font-family: 'Inter Bold', sans-serif;
				font-size: 24px;
				line-height: 32px;
			}
			.release {
				font-family: 'Inter', sans-serif;
				font-size: 14px;
				line-height: 24px;
				color: #7a7a7a;
			}
			.main-release-content {
				font-family: 'Inter', sans-serif; font-size: 16px; line-height: 24px;
			}
			.release-content {
				font-family: 'Inter', sans-serif; font-size: 16px; line-height: 24px; padding-top: 20px;
			}
			.title {
				font-family: 'Inter Bold', sans-serif; font-size: 18px; line-height: 24px;
			}
			.title-2 {
				font-family: 'Inter Bold', sans-serif; font-size: 16px; line-height: 20px;
			}
			.release-disclaimer {
				font-size: 16px; color:Tomato;
			}
			ul {
				font-family: 'Inter', sans-serif; font-size: 16px; line-height: 24px; padding: 2px 0 0;
				height: auto; display: inline-table; margin-left: 1em;
			}
			ul li {
				list-style-type: disc;
				line-height: 32px;
				max-height: max-content;
			}
		}

		.content::-webkit-scrollbar {
			width: 7.3px;
			border-radius: 4px;
			overflow: hidden;
			margin-right:5px;
		}
		.content::-webkit-scrollbar-thumb {
			background: #949494;
			border-radius: 4px;
		}
		.content::-webkit-scrollbar-track {
			background: #D8D8D8;
			border-radius: 4px;
		}
	}
	.modal-footer {
		justify-content: center;
		padding: 0 12px 12px;
	}

	.close {
		font-size: 37px;
		font-weight: bold;
		opacity: 1;
		margin-top: 0;
		padding: 0;
		cursor: pointer;
		outline: none;
		margin-right: 12px;
		font-family: initial;
	}
	.close:before, .close:after {
		display: none;
	}

	.carousel-placeholder-image {
		top: 27%;
		left: 12%;
	}
}


/*------- Dark theme ---------*/

.dark {
	.logo-wrapper {
		background-color: black;
	}
	#sidebar {
		background-color: #181818;
		.menu-item1 {
			background-color: #181818;
		}
		.menu-item2 {
			background-color: #101010;
		}
	}
	.main-content {
		background-color: black;
		.select__control {
			background-color: black;
		}
		.select__menu {
			background-color: black;
			.select__option--is-focused {
				background-color: #181818;
			}
		}
		.card {
			background-color: #181818;
			color: white;
		}
		.basic-multi-select {
			background-color: #181818;
			color: white;
		}
	}
	.navbar {
		background-color: black;
		color: white;
	}
}

@keyframes blink {
	0% {
		opacity: .2;
	}
	20% {
		opacity: 1;
	}
	100% {
		opacity: .2;
	}
}

.loading span {
	animation-name: blink;
	animation-duration: 1.4s;
	animation-iteration-count: infinite;
	animation-fill-mode: both;
}

.loading span:nth-child(2) {
	animation-delay: .2s;
}

.loading span:nth-child(3) {
	animation-delay: .4s;
}


.current-user-photo {
	margin-left: -10px;
	margin-top: -10px;
	clip-path: circle(17px at center);
}
.fsyBwk{
	background:#d8e0e7 !important;
}
.app-disclaimer{
	z-index:1001;
	top:0px;
	position:fixed;
	left:0px;
	background-color:#FFD829;
	color:#000;
	width:100%;
	display: initial;
	font-family:'Inter SemiBold';
	font-size:16px;
	text-align:center;
	height: 40px;
	overflow: hidden;
	p{
		line-height:40px;
	}
}
.app-disclaimer.default {
	background-color:#FFD829;
	p {
		color:#000000;
	}
}
.app-disclaimer.expired, .app-disclaimer.future {
	background-color:#E3ECF3;
	p {
		color:#194A74;
	}
}
.simplebar-content{
	height: calc(100% - 40px) !important;
	margin-top: 25px !important;
}

.subscription-banner {
	width: 1032px;
	/* height: 57px; */
	margin-top: 20px;
	margin-bottom: 37px;
	border-radius: 2px;
	background-color: #000000;

	.message {
		/* height: 17px; */
		color: rgba(255,255,255,0.87);
		font-family: 'Inter', sans-serif;
		font-size: 18px;
		font-weight: 600;
		/* letter-spacing: 0; */
		/* line-height: 59px; */
		padding-left: 32px;
		padding-top: 28px;
		padding-bottom: 28px
	}

	.message2 {
		/* height: 17px; */
		color: #ADADAD;
		font-family: 'Inter', sans-serif;
		font-size: 16px;
		font-weight: 500;
		/* letter-spacing: 0; */
		/* line-height: 59px; */
		padding-left: 32px;
		padding-top: -20px;
		padding-bottom: 28px;
		margin-top: -15px;
	}

	.message3 {
		color: rgba(255,255,255,0.87);
		font-size: 18px;
		font-weight: 600;
	}
}