.coverage-token-title + .coverage-tooltip {
  display:none;
  position:absolute;
  width: 100%;
  top : 100%;
}

.coverage-token-title:hover + .coverage-tooltip {
  display:flex;
  position:absolute;
  float: left;
  top: 87%;
  left: 5%;
  background: #222222;
  opacity: 1;
  z-index: 2;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #ffffff;
  font-size: 14px;
  font-weight: normal;
  line-height: 14px;
  font-family: 'Inter', sans-serif;
  width: 100%;
}

.coverage-token-title + .coverage-tooltip ul {
  color: white;
  font-size: 14px;
  font-family: sans-serif;
  text-align: left;
  margin-bottom: 0;
  padding-left: 15px;
}

.coverage-token-title + .coverage-tooltip {
  color: black;
  stroke: white;
}